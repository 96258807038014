import { PAGE_TITLES } from '../../../core/utils/utils';

export const NOT_FEATURED_POST_DATE = '-';

export const CMS = 'cms';

export const CMS_PAGES = {
  EDITOR_DASHBOARD: 'Editor Table',
  EDITOR_DRAFT: 'Editor Draft',
  ECHO_DASHBOARD: 'Publisher Table',
  ECHO_CUSTOMIZATION: 'Publisher Customization',
};

export const MAP_CMS_PAGES_TO_PAGE_TITLE = {
  [CMS_PAGES.EDITOR_DRAFT]: PAGE_TITLES.EDITOR,
  [CMS_PAGES.EDITOR_DASHBOARD]: PAGE_TITLES.EDITOR,
  [CMS_PAGES.ECHO_CUSTOMIZATION]: PAGE_TITLES.ECHO_PUBLISHER,
  [CMS_PAGES.ECHO_DASHBOARD]: PAGE_TITLES.ECHO_PUBLISHER,
};

export const SWIMSUIT_SLUG = 'si_swimsuit';

export const IN_THIS_STORY_PROPERTIES = new Set([
  'all_49ers',
  'bear_digest',
  'all_bengals',
  'bills_central',
  'mile_high_huddle',
  'browns_digest',
  'bucs_gameday',
  'all_cardinals',
  'charger_report',
  'arrowhead_report',
  'horseshoe_huddle',
  'commander_country',
  'cowboys_country',
  'all_dolphins',
  'eagles_today',
  'falcon_report',
  'giants_country',
  'jaguar_report',
  'jets_country',
  'all_lions',
  'packer_central',
  'all_panthers',
  'patriots_country',
  'raiders_today',
  'ram_digest',
  'raven_country',
  'saints_news_network',
  'all_seahawks',
  'all_steelers',
  'texans_daily',
  'all_titans',
  'inside_the_vikings',
  'nfl_draft',
  'bama_central',
  'all_sun_devils',
  'all_hogs',
  'auburn_daily',
  'inside_the_bears',
  'bc_bulletin',
  'cougs_daily',
  'cal_sports_report',
  'all_bearcats',
  'all_clemson',
  'buffs_beat',
  'college_football_hq',
  'blue_devil_country',
  'all_gators',
  'nole_gameday',
  'g5_football_daily',
  'dawgs_daily',
  'all_yellow_jackets',
  'gonzaga_nation',
  'hbcu_legends',
  'hoosiers_now',
  'inside_the_hawkeyes',
  'blue_wings_rising',
  'wildcats_today',
  'louisville_report',
  'lsu_country',
  'all_hurricanes',
  'wolverine_digest',
  'spartan_nation',
  'all_gophers',
  'cowbell_corner',
  'mizzou_central',
  'all_wolfpack',
  'all_huskers',
  'all_tar_heels',
  'wildcats_daily',
  'irish_breakdown',
  'buckeyes_now',
  'all_sooners',
  'the_grove_report',
  'ducks_digest',
  'all_penn_state',
  'Inside_the_panthers',
  'boilermakers_country',
  'inside_the_mustangs',
  'gamecock_digest',
  'all_cardinal',
  'all_syracuse',
  'volunteer_country',
  'longhorns_country',
  'all_aggies',
  'killer_frogs',
  'red_raider_review',
  'tmg_sports',
  'inside_the_knights',
  'all_bruins',
  'huskies_report',
  'all_trojans',
  'all_utes',
  'cavaliers_now',
  'deacons_daily',
  'inside_the_huskies',
  'mountaineers_now',
  'all_badgers',
  'halos_today',
  'inside_the_astros',
  'inside_the_as',
  'inside_the_blue_jays',
  'braves_today',
  'inside_the_cardinals',
  'inside_the_cubs',
  'inside_the_diamondbacks',
  'inside_the_dodgers',
  'fastball',
  'giants_baseball_insider',
  'cleveland_baseball_insider',
  'inside_the_mariners',
  'inside_the_marlins',
  'inside_the_mets',
  'inside_the_orioles',
  'inside_the_padres',
  'inside_the_phillies',
  'inside_the_rangers',
  'inside_the_rays',
  'inside_the_red_sox',
  'inside_the_royals',
  'inside_the_twins',
  'inside_the_pinstripes',
  'all_76ers',
  'back_in_the_day_nba',
  'bucks_zone',
  'bulls_news',
  'cavs_insider',
  'inside_the_celtics',
  'all_clippers',
  'fastbreak',
  'all_grizzlies',
  'all_hawks',
  'inside_the_heat',
  'all_hornets',
  'inside_the_jazz',
  'inside_the_kings',
  'all_knicks',
  'all_lakers',
  'the_magic_insider',
  'dallas_basketball',
  'nba_draft',
  'inside_the_nets',
  'inside_the_nuggets',
  'all_pacers',
  'pelicans_scoop',
  'all_pistons',
  'all_raptors',
  'inside_the_rockets',
  'inside_the_spurs',
  'inside_the_suns',
  'inside_the_thunder',
  'all_timberwolves',
  'inside_the_warriors',
  'inside_the_wizards',
  'bring_me_the_sports',
  'auto_racing_digest',
  'f1briefings',
  'fannation_futbol',
  'sunderland_nation',
  'mma_knockout',
  'bleav',
  'rodeo_daily',
  'name_image_likeness',
  'si',
  'esports_illustrated',
  'si_press_room',
  'si_health',
  'si_news',
  'dink_it_over',
  'wrestling_on_fannation',
  'all_hokies',
  'cfl',
  'ufl',
  'si_fannation',
  'inside_the_wildcats',
  'fcs_football_central',
  'illini_now',
  'kstate',
  'all_terrapins',
  'all_pokes',
  'vanderbilt_commodores',
  'all_hokies',
  'si_fannation',
  'inside_the_astros',
  'cleveland_baseball_insider',
  'pittsburgh_pirates',
  'inside_the_reds',
  'tigers_baseball_report',
  'south_side_hit_pen',
  'all_trail_blazers',
  'commander_country',
  'the_athlete_lifestyle_on_si',
  'milwaukee_brewers_on_si',
  'breakaway_on_si',
  'kicks',
  'rugby_on_si',
  'tulane_green_wave_on_si',
  'houston_cougars_on_si',
  'washington_nationals_on_si',
  'racing_america_on_si',
  'oregon-state-beavers-on-si',
  'washington-state-cougars-on-si',
  'floor8_en_international_web', // TODO: remove after QA
  'site-qa-2', // TODO: remove after QA
  'sandbox_for_tests',
]);
